@import 'scss/_icons_otic';

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "theme/theme.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";

/* json editor*/
@import "../node_modules/jsoneditor/dist/jsoneditor.min.css";

/*OTIC*/
@import 'scss/icons_otic';

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat.ttf') format('truetype');
  font-weight: 100 900;
 }
 
 @font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito.ttf') format('truetype');
  font-weight: 200 1000;
 }
 

:root {
    --primary-color: #266fe0;
    --otic-navy-blue: #184d9b;
    --otic-night-blue: #1d2345
    --otic-gray: #474756;

    --secondary-color: #ff8810;
    --otic-success: #00c56b;
    --otic-link: #0f0bab;
    --otic-error: #ed475b;
    --otic-warning: #ffd058;
    --otic-tables: #007bbf;

    --otic-gray-100: #1a141f;
    --otic-gray-90: #4b3a5a;
    --otic-gray-70: #aba7af;
    --otic-gray-60: #d4d2d5;
    --otic-gray-40: #d9d1e0;
    --otic-gray-30: #e5e0eb;
    --otic-gray-20: #f5f3f7;

    --radius-small: 4px;
    --radius-chip: 6px;
    --radius-medium: 8px;
    --radius-large: 12px;
    --radius-huge: 24px;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #1D2345;
}

h3 {
  margin: 14px 0 14px 0;
  font-size: 20px;
}

p {
  font-family: 'Nunito', sans-serif;
}

label {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #1D2345;
}

input {
  font-family: 'Nunito', sans-serif;
}

th {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

td {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.step-title {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 6px;
  }

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #ffff;
    border: 2px solid var(--primary-color);
    background: #ffff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #BFDBFE;
  }

  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: var(--primary-color);
    color: #ffff;
  }

  .p-button {
    min-width: 148px;
    max-height: 46px;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 700;
    background-color: var(--otic-navy-blue);
    border-radius: var(--radius-small);
    &:hover {
        background-color: var(--primary-color);
    }
    &:focus {
        background-color: #042e6c;
    }
    &:disabled {
      color: var(--otic-gray-70);
      border-color: var(--otic-gray-20);
      background-color: var(--otic-gray-20);
  }
  }

  .p-button-label {
    font-weight: 400;
  }

  .p-button-outlined {
    background-color: #ffff;
    color: var(--otic-navy-blue);
    &:hover {
        border-color: var(--primary-color);
        color: var(--primary-color);
    }
    &:focus {
        border-color: #042e6c;
        color: #042e6c;
    }

    .p-button-label {
      font-weight: 700;
    }
  }
  
  .p-dropdown {
    background: #ffffff;
    border: 1px solid #d1d5db;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
  }
  .p-dropdown.p-invalid.p-component {
    border-color: #e24c4c;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: #3B82F6;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    border-color: #3B82F6;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
  }
  .p-dropdown .p-dropdown-label {
    font-family: 'Nunito', sans-serif;
    background: transparent;
    border: 0 none;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    font-family: 'Nunito', sans-serif;
    color: #474756;
  }
  .p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: var(--otic-navy-blue);
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: var(--otic-navy-blue);
    right: 3rem;
  }
  
  .p-dropdown-panel {
    background: #ffffff;
    color: #4b5563;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #e5e7eb;
    color: #374151;
    background: #f9fafb;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: var(--otic-navy-blue);
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0.75rem 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #4b5563;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #1D4ED8;
    background: #EFF6FF;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: rgba(59, 130, 246, 0.24);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1.25rem;
    color: #374151;
    background: #ffffff;
    font-weight: 700;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.75rem 1.25rem;
    color: #4b5563;
    background: transparent;
  }
  .p-input-filled .p-dropdown {
    background: #f3f4f6;
  }
  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f3f4f6;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #ffffff;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
  }

  p-dropdown.ng-invalid.ng-touched:not(form) > div {
    border-color: #ED475B;
    &:focus {
      border-color: #ED475B;
    }
    &:hover {
      border-color: #ED475B;
    }
  }

  p-inputMask.ng-invalid.ng-touched {
    input {
      border-color: #ED475B;
    }
  }

  .p-inputtext {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: var(--radius-small);
    border: 1px solid #dbdbdb;
    color: #474756;
    &:focus {
      box-shadow: none;
      border-color: #042e6c;
    }
    &:hover {
      border-color: #266FE0;
    }
    &:disabled {
        color: var(--otic-gray-70);
        border-color: var(--otic-gray-20);
        background-color: var(--otic-gray-20);
    }
  }

  .p-inputtext.ng-touched.ng-invalid {
    border-color: #ED475B;
    &:focus {
      border-color: #ED475B;
    }
    &:hover {
      border-color: #ED475B;
    }
  }

  .p-datatable-thead > tr > th {
    background-color: var(--otic-tables);
    color: white;
    border: 0 none;
    font-weight: 700;
  }



.p-dialog .p-dialog-header{
  padding: 15px 25px 15px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background-color: #F9FAFC;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.15);
}

.p-radiobutton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-radiobutton-box {
  width: 16.67px;
  height: 16.67px;
  border: 2px solid #042E6C;
  background: #ffff;
  border-radius: 50%;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  background-color: #042E6C;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background-color: white;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
  }
}

.p-radiobutton.p-disabled {
  .p-radiobutton-box {
    border-color: #ABA7AF;
    background-color: #ABA7AF;
  }
  
  .p-radiobutton-icon {
    background-color: #ABA7AF;
  }
}

.p-dialog-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #1D2345;
}

.p-dialog-content {
  padding: 20px 30px 30px 30px;
  overflow-y: auto;
}

.p-dialog-header-close {
  width: 20px;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}


.otic-form-s-input {
  width: 360px;
}

.otic-form-input {
  width: 380px;
}

.otic-form-l-input {
  width: 385px;
}

.otic-form-xl-input {
  width: 632px;
}

.mb-20px {
  margin-bottom: 20px;
}

.p-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-checkbox .p-checkbox-box {
  width: 15px;
  height: 15px;
  border: 2px solid var(--otic-navy-blue);
  border-radius: 2px;
  box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--otic-navy-blue);
}

.p-checkbox-icon {
  width: 12px;
  height: 12px;
}

.p-calendar {
  width: 380px;
  height: 40px;

  &:disabled {
    background-color: var(--otic-gray-20);
  }
}

.p-datepicker-icon {
  color: #184D9B;
}


.p-tabview .p-tabview-panels {
  padding: 5px 0px 0px 0px;
}

/*Estilos para fuentes*/

.font-nunito-s18-w600-color1{
  font-family: 'Nunito', sans-serif;
  color: #1D2345;
  font-size: 18px;
  font-weight: 600;
}

.font-nunito-s16-w700-color1{
  font-family: 'Nunito', sans-serif;
  color: #1D2345;
  font-size: 16px;
  font-weight: 700;
}

.font-nunito-s16-w600-color1{
  font-family: 'Nunito', sans-serif;
  color: #1D2345;
  font-size: 16px;
  font-weight: 600;
}

.font-nunito-s16-w400-color1{
  font-family: 'Nunito', sans-serif;
  color: #1D2345;
  font-size: 16px;
  font-weight: 400;
}

.font-nunito-s18-w400-color2{
  font-family: 'Nunito', sans-serif;
  color: #474756;
  font-size: 18px;
  font-weight: 400;
}

.font-nunito-s16-w400-color2{
  font-family: 'Nunito', sans-serif;
  color: #474756;
  font-size: 16px;
  font-weight: 400;
}

.font-nunito-s16-w600-color4{
  font-family: 'Nunito', sans-serif;
  color: #184D9B;
  font-size: 16px;
  font-weight: 600;
}

.font-nunito-s16-w400-color4{
  font-family: 'Nunito', sans-serif;
  color: #184D9B;
  font-size: 16px;
  font-weight: 400;
}

.font-nunito-s20-w600-color4{
  font-family: 'Nunito', sans-serif;
  color: #184D9B;
  font-size: 20px;
  font-weight: 600;
}

.font-montserrat-s18-w600-color3{
  font-family: 'Montserrat', sans-serif;
  color: #184D9B;
  font-size: 18px;
  font-weight: 600;
}

.p-chips {
  width: 100%;

  .p-chips-multiple-container {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;

    .p-chips-token {
      background: var(--otic-navy-blue);
      border-radius: var(--radius-chip);
      color: white;

    }
  }
}

.p-autocomplete {
  width: 100%;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 2px 12px 2px 12px;
  width: 100%;
  height: auto;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  display: flex;
  align-items: center;
  max-width: 230px;
  background: var(--otic-navy-blue);
  border-radius: var(--radius-chip);
  color: white;
}

.p-autocomplete-token-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.p-autocomplete-token-icon {
  flex-shrink: 0;
  margin-left: 8px;
}

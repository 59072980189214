.otic-meta {
    margin: 0;
    width: 29px;
    height: 28px;
    padding: 5.6px 8.65px 5.6px 10.16px;
    cursor: pointer;
}

.otic-x {
    margin: 0;
    width: 29px;
    height: 28px;
    padding: 5.6px 5.8px;
    cursor: pointer;
}

.otic-instagram {
    margin: 0;
    width: 29px;
    height: 28px;
    padding: 5.6px 5.8px;
    cursor: pointer;
}

.otic-linkedin {
    margin: 0;
    width: 29px;
    height: 28px;
    padding: 5.83px 5.44px;
    cursor: pointer;
}

.otic-menu {
    width: 28.38px;
    height: 18.34px;
    background: url('../assets/icons/menu.svg') no-repeat center center;
}

.otic-sidebar-menu {
    width: 28.38px;
    height: 18.34px;
    background: url('../assets/icons/sidebarmenu.svg') no-repeat center center;
}


.otic-actions {
    margin: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/accion.svg') no-repeat center center;
}

.otic-edit {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/edit.svg') no-repeat center center;
}

.otic-delete {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/delete.svg') no-repeat center center;
}

.otic-view {
    margin-right: 6px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/view.svg') no-repeat center center;
}

.otic-home {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/home.svg') no-repeat center center;
}

.otic-potential {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/potenciales.svg') no-repeat center center;
}

.otic-fec {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/fec.svg') no-repeat center center;
}

.otic-check-active {
    width: 40px;
    height: 40px;
    background: url('../assets/icons/check_active.svg') no-repeat center center;
}

.otic-check-inactive {
    width: 40px;
    height: 40px;
    background: url('../assets/icons/check_inactive.svg') no-repeat center center;
}

.otic-check-complete {
    width: 40px;
    height: 40px;
    background: url('../assets/icons/check_complete.svg') no-repeat center center;
}


.otic-plus {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/plus.svg') no-repeat center center;
}

.otic-plus-blue {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/plus-blue.svg') no-repeat center center;
}


.otic-gear {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/gear.svg') no-repeat center center;
}

.otic-user-admin {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/user-admin.svg') no-repeat center center;
}


.otic-clip {
    width: 16px;
    height: 16px;
    background: url('../assets/icons/clip.svg') no-repeat center center;
}

.otic-close {
    width: 12px;
    height: 12px;
    cursor: pointer;
    background: url('../assets/icons/x.svg') no-repeat center center;
}

.otic-edit-small {
    margin-right: 6px;
    width: 13.5px;
    height: 13.5px;
    cursor: pointer;
    background: url('../assets/icons/edit.svg') no-repeat center center;
}

.otic-view-small {
    margin-right: 6px;
    width: 13.5px;
    height: 13.5px;
    cursor: pointer;
    background: url('../assets/icons/view.svg') no-repeat center center;
}

.otic-fec-check {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/fec-check.svg') no-repeat center center;
}

.otic-download {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: url('../assets/icons/download.svg') no-repeat center center;
}